* {
  font-family: "Inter" !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

/* .p-4px {
  padding: 4px !important;
} */

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-start {
  justify-content: flex-start !important;
}

.flex-center {
  justify-content: center !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.space-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.color-2E62E9 {
  color: #2e62e9 !important;
}

.color-364152 {
  color: #364152 !important;
}

.color-121926 {
  color: #121926 !important;
}

.color-426BCD {
  color: #426bcd !important;
}

.font-size-10px {
  font-size: 10px !important;
}

.font-size-12px {
  font-size: 12px !important;
}

.font-size-14px {
  font-size: 14px !important;
}

.font-size-16px {
  font-size: 16px !important;
}

.font-size-17px {
  font-size: 17px !important;
}

.font-size-28px {
  font-size: 28px !important;
}

.font-size-30px {
  font-size: 30px !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.gap-3px {
  gap: 3px !important;
}

.gap-1 {
  gap: 0.5rem !important;
}

.gap-2 {
  gap: 1rem !important;
}

.w-100 {
  width: 100% !important;
}

.w-25 {
  width: 25% !important;
}

.w-24px {
  width: 24px !important;
}

.w-34px {
  width: 34px !important;
}

.w-40px {
  width: 40px !important;
}

.w-220px {
  width: 220px !important;
}

.w-30 {
  width: 30% !important;
}

.w-50 {
  width: 50% !important;
}

.w-70 {
  width: 70% !important;
}

.h-100 {
  height: 100% !important;
}

.h-100vh {
  height: 100vh !important;
}

.h-50vh {
  height: 50vh !important;
}

.h-24px {
  height: 24px !important;
}

.h-34px {
  height: 34px !important;
}

.h-40px {
  height: 40px !important;
}

.h-130px {
  height: 130px !important;
}

.border-none {
  border: none !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.br-5px {
  border-radius: 5px !important;
}

.border-radius-8px {
  border-radius: 8px !important;
}

.border-radius-12px {
  border-radius: 12px !important;
}

.border-radius-circle {
  border-radius: 50% !important;
}

.border-E3E8EF {
  border: 1px solid #e3e8ef !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.p-8-12 {
  padding: 8px 12px !important;
}

.pl-2 {
  padding-left: 1rem !important;
}

.pr-2 {
  padding-right: 1rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-5px {
  padding-top: 5px !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.border-bottom-506fff {
  border-bottom: 1px solid #506fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-EEF2F6 {
  background-color: #eef2f6 !important;
}

.bg-E3E8EF {
  background-color: #e3e8ef !important;
}

.bg-FFF9EB {
  background-color: #fff9eb !important;
}

.bg-FFE7AD {
  background-color: #ffe7ad !important;
}

.bg-FFF3D6 {
  background-color: #fff3d6 !important;
}

.bg-EAEFFD {
  background-color: #eaeffd !important;
}

.btn-padoff {
  padding: 0 !important;
  height: auto !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  min-width: 70px !important;
}

.btn-padoff-text {
  font-size: 10px !important;
  color: #6c6c89 !important;
}

.activate-icon .btn-padoff-text {
  color: #fff !important;
}

.color-697586 {
  color: #697586 !important;
}

.color-506FFF {
  color: #506fff !important;
}

.box-shadow-unset {
  box-shadow: unset !important;
}

.outline-none {
  outline: none !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.user-role {
  display: inline-block;
  text-transform: lowercase;
}

.user-role:first-letter {
  text-transform: uppercase;
}

.call-control-leave-dropdown ul li:nth-child(2) {
  padding: 0 !important;
}

.call-control-more-dropdown {
  max-width: 320px !important;
}

li.no-arrow .ant-dropdown-menu-submenu-arrow {
  display: none !important;
}

.call-control-more-dropdown ul li {
  padding: 0 !important;
}

.ant-dropdown-menu-submenu ul li {
  padding: 0 !important;
}

.position-relative {
  position: relative !important;
}

.position-fixed {
  position: fixed !important;
}

.position-absolute {
  position: absolute !important;
}

.left-0 {
  left: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.r-10px {
  right: 10px !important;
}

.top-0 {
  top: 0 !important;
}

.top-2 {
  top: 1rem !important;
}

.top-8 {
  top: 4rem !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-2 {
  bottom: 1rem !important;
}

.top-10px {
  top: 10px !important;
}

.right-10px {
  right: 10px !important;
}

.right-2 {
  right: 1rem !important;
}

.color-white {
  color: #fff !important;
}

.activate-icon {
  background-color: #547eed !important;
  color: #fff !important;
  border-radius: 0 !important;
}

.bg-F4F1FD {
  background-color: #f4f1fd !important;
}

.bg-00000080 {
  background-color: #00000080 !important;
}

.p-3px {
  padding: 3px !important;
}

.bs {
  box-shadow: 0px 4px 6px -2px #1212170d, 0px 10px 15px -3px #12121714;
}

.user {
  width: 200px !important;
  height: 112.5px !important;
  overflow: hidden;
  position: relative;
}

.localUser,
.remoteUser {
  border-radius: 8px;
  border: 1px solid transparent;
}

.user > div {
  background-color: #ffffff !important;
}

.user > div.isScreen {
  height: calc(100% - 22px - 0.5rem) !important;
}

.user > div.screenPresented {
  background-color: #e3e8ef !important;
}

.user-name {
  font-size: 14px;
  line-height: 20px;
  z-index: 2;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  height: 28px;
  width: 100%;
  border-radius: 0 8px 0 8px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}

.user-name.stream-on .ant-typography {
  color: #ffffff;
}

.user-name.stream-off {
  background: transparent;
}

.user-name.stream-on {
  background: #00000080;
}

.text-capitalize {
  text-transform: capitalize;
}

.middle-user {
  width: 100% !important;
  height: auto !important;
}

.dynamic-grid-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dynamic-grid {
  display: grid;
  gap: 10px;
}

.dynamic-grid.grid-1 {
  grid-template-columns: 1fr;
}

.dynamic-grid.grid-2 {
  grid-template-columns: 1fr 1fr;
  height: 50% !important;
}

.dynamic-grid.grid-3 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.dynamic-grid.grid-3 > :nth-child(3) {
  grid-column: span 2;
  justify-self: center;
  width: 50% !important;
}

.dynamic-grid.grid-4 {
  grid-template-columns: 1fr 1fr;
}

.dynamic-grid.grid-5 {
  grid-template-columns: 1fr 1fr 1fr;
}

.dynamic-grid.grid-6 {
  grid-template-columns: 1fr 1fr 1fr;
}

.dynamic-grid.grid-7 {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
}

.dynamic-grid.grid-7 > :nth-child(7) {
  grid-column: 2;
}

.dynamic-grid.grid-8 {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
}

.dynamic-grid.grid-9 {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
}

.dynamic-grid.grid-10 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
}

.dynamic-grid.grid-10 > :nth-child(9) {
  grid-column: 2 / 3;
  justify-self: end;
}

.dynamic-grid.grid-10 > :nth-child(10) {
  grid-column: 3 / 4;
  justify-self: start;
}

.ant-btn:not(:disabled):focus-visible {
  outline: none !important;
}

.zi-tooltip {
  z-index: 99999 !important;
}

.ant-tooltip .ant-tooltip-inner {
  background-color: #121926 !important;
  text-align: center !important;
  color: white !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.zi-10k {
  z-index: 10000 !important;
}

.zi-11k {
  z-index: 11000 !important;
}

[id^="agora-video-player-track-video"] {
  background-color: white !important;
}

.bg-F8FAFC {
  background-color: #f8fafc !important;
}

.chatbox-tabs .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.chatbox-tabs .ant-tabs-content,
.chatbox-tabs .ant-tabs-tabpane {
  height: 100% !important;
}

.chat-input .ant-input-outlined:focus,
.chat-input .ant-input-outlined:focus-within {
  border-color: #e3e8ef !important;
}

.msg-view {
  border-radius: 0 8px 8px 8px;
}

.chat-input-container textarea,
.chat-input-container textarea:hover,
.chat-input-container textarea:focus {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.chat-input-container {
  border-radius: 0 !important;
  border: 1px solid #e3e8ef !important;
  border-left: none !important;
  border-bottom: none !important;
  border-right: none !important;
}

.chat-input-container:focus-within {
  box-shadow: 0 0 0 2px rgba(5, 122, 255, 0.06) !important;
  border-color: #2e62e9 !important;
}

.ant-select-dropdown {
  z-index: 11000 !important;
}

.resize-none {
  resize: none !important;
}

html {
  scroll-behavior: smooth;
}

body ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
body ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #b8bdba;
}
body ::-webkit-scrollbar-track {
  background: transparent;
}

.word-break {
  word-break: break-word;
}

.rec-btn.ant-btn-text:not(:disabled):not(.ant-btn-disabled):active,
.rec-btn.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover,
.rec-btn.ant-btn:not(:disabled):focus-visible,
.rec-btn.ant-btn:not(:disabled):focus-visible {
  outline: none !important;
  background: none !important;
}

.red-dot {
  position: absolute;
  right: 8px;
  top: 3px;
}

video {
  box-sizing: unset !important;
}

.ant-message {
  top: 60px !important;
  z-index: 11000 !important;
}

.recTooltip .ant-tooltip-inner {
  width: 320px;
}

.text-black {
  color: #000000 !important;
}

.lang-sel button.secondary-btn {
  background: #fff;
  color: #364152 !important;
  border: 1px solid #e3e8ef;
}

.lang-sel button {
  height: 52px;
  min-width: 152px;
  color: #fcfcfc;
  padding: 10px 40px;
  border-radius: 8px;
  background: #01a3ae;
  border: 1px solid #01a3ae;
  transition: all 0.25s ease-out;
}

.lang-sel button:hover {
  color: white !important;
  background: #01a3ae !important;
  border: 1px solid #01a3ae !important;
}

.lang-sel button.secondary-btn:hover {
  color: black !important;
  background: #eeeeee !important;
  border: 1px solid #eeeeee !important;
}
